import React from "react"
// import TransitionLink from 'gatsby-plugin-transition-link'
import Layout from "../components/layout"
import SEO from "../components/shared/seo"

function Contact() {
  return <a href="mailto:Info.Wilding@gmail.com"></a>
}

export default Contact
